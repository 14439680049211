import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="S" transform="translate(35.800000, 28.800000)">
        <path
          d="M 0 29.246 L 4.619 29.246 A 7.776 7.776 0 0 0 5.376 31.858 A 6.823 6.823 0 0 0 7.399 34.255 Q 9.653 35.955 13.195 36.06 A 15.931 15.931 0 0 0 13.669 36.067 Q 16.898 36.067 19.06 34.767 A 7.487 7.487 0 0 0 19.873 34.201 Q 22.209 32.334 22.209 29.219 A 5.809 5.809 0 0 0 21.868 27.19 A 5.136 5.136 0 0 0 20.424 25.11 A 7.949 7.949 0 0 0 18.944 24.094 Q 17.29 23.194 14.717 22.559 L 11.387 21.727 A 26.02 26.02 0 0 1 8.294 20.747 Q 6.747 20.141 5.539 19.385 A 10.776 10.776 0 0 1 3.464 17.725 A 8.935 8.935 0 0 1 1.079 12.766 A 12.417 12.417 0 0 1 0.94 10.877 A 11.554 11.554 0 0 1 1.488 7.238 A 9.458 9.458 0 0 1 4.391 2.955 A 11.785 11.785 0 0 1 9.242 0.507 Q 10.991 0.064 13.048 0.008 A 22.079 22.079 0 0 1 13.643 0 A 16.827 16.827 0 0 1 17.615 0.445 A 11.989 11.989 0 0 1 22.585 2.914 A 10.233 10.233 0 0 1 26.255 10.139 A 13.727 13.727 0 0 1 26.292 10.662 L 21.672 10.662 A 7.959 7.959 0 0 0 21.055 8.38 A 6.356 6.356 0 0 0 19.188 5.976 A 7.415 7.415 0 0 0 16.271 4.585 Q 15.193 4.324 13.932 4.3 A 13.557 13.557 0 0 0 13.669 4.297 Q 9.937 4.297 7.828 5.976 A 5.46 5.46 0 0 0 5.745 9.977 A 7.727 7.727 0 0 0 5.72 10.608 A 6.069 6.069 0 0 0 6.002 12.498 A 4.981 4.981 0 0 0 7.426 14.677 A 7.515 7.515 0 0 0 8.844 15.667 Q 10.314 16.479 12.551 17.068 A 27.009 27.009 0 0 0 12.917 17.161 L 16.301 18.021 Q 19.854 18.9 22.17 20.254 A 10.753 10.753 0 0 1 24.465 22.009 Q 26.99 24.6 26.99 28.978 A 12.081 12.081 0 0 1 26.414 32.792 A 9.883 9.883 0 0 1 23.364 37.276 Q 19.931 40.2 14.284 40.356 A 23.304 23.304 0 0 1 13.643 40.364 Q 7.627 40.364 3.961 37.383 Q 0.295 34.402 0 29.246 Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
