import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { email } from '@config';
import { navDelay, loaderDelay } from '@utils';
import { usePrefersReducedMotion } from '@hooks';
import imagePattern from '../../images/pattern-dark.png';

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  padding: 0;

  @media (max-width: 480px) and (min-height: 700px) {
    padding-bottom: 10vh;
  }

  h1 {
    margin: 0 0 30px 4px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 400;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h2 {
    color: var(--lightest-slate);
  }

  h3 {
    margin-top: 10px;
    // background: linear-gradient(90deg, rgba(195,166,255,1) 15%, rgba(92,207,230,1) 100%);//linear-gradient(90deg, rgba(195,166,255,1) 15%, rgba(92,207,230,1) 100%);//linear-gradient(90deg, rgba(195,166,255,1) 0%, rgba(239,107,115,1) 50%, rgba(255,213,128,1) 100%);
    background-color: var(--green);
    background-image: url(${imagePattern});
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media (max-width: 480px) {
      margin-top: -5px;
    }

  }

  h4 {
    margin-top: 10px;
    color: var(--slate);
    line-height: 0.9;
  }

  // .video-container {
  //   .bg-video {
  //     position: absolute;
  //     width: 540px;
  //     height: 149px;
  //     z-index: -100;
  //     transition: 1s opacity;
  //   }
  // }

  // svg{
  //   position: absolute;
  //   width: 100%;
  //   height: inherit;

  //   .text{
  //   }
    
  //   #alpha{
  //     fill: white;
  //   }

  //   #title{
  //     font-size: 3.6em;
  //     font-weight: 700;
  //   }

  //   #subtitle{
  //     letter-spacing: 8px;
  //     font-size: 1.2em;
  //     font-weight: 300;
  //     text-transform: uppercase;
  //   }

  //   #base{
  //     fill: var(--navy);
  //     mask: url(#mask);
  //   }
  // }

  p {
    margin: 20px 0 0;
    max-width: 540px;
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }

`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {

    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);


  const one = <h1>Hi, my name is</h1>;
  const two = <h2 className="big-heading">Sugam Budhraja.</h2>;
  const three = <h4 className="big-heading">I develop sophisticated</h4>;
  const four = (
    <h3 className="big-heading">AI applications.</h3>
    // <div className="video-container">
    //   <video className="bg-video" autoPlay playsInline muted loop preload poster="https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/oceanshot.jpg">
    //     <source src={heroVideo} />
    //   </video>
    //   <svg>
    //     <defs>
    //       <mask id="mask" x="0" y="0" width="100%" height="100%" >
    //         <rect id="alpha" x="0" y="0" width="100%" height="100%" />
    //         <text id="title" x="0"  y="40%">AI applications</text>
    //       </mask>
    //     </defs>
    //     <rect id="base" x="0" y="0" width="100%" height="100%" />
    //   </svg>
    // </div>
  );
  const five = (
    <a href={`mailto:${email}`} className="email-link">
      Get In Touch
    </a>
  );

  const items = [one, two, three, four, five];

  return (
    
    <StyledHeroSection>
      {prefersReducedMotion ? (
        <>
          {items.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </>
      ) : (
        <TransitionGroup component={null}>
          {isMounted &&
            items.map((item, i) => (
              <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
                <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
              </CSSTransition>
            ))}
        </TransitionGroup>
      )}
    </StyledHeroSection>
  );
};

export default Hero;
